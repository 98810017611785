import React from 'react';
import NextLink from 'next/link';
import { FormattedMessage, useIntl } from 'react-intl';
import { always, propEq } from 'ramda';
import { useRouter } from 'next/router';
import { useAsync } from 'react-use';

import {
  CancelLink,
  Item,
  MenuItem,
  MobileBagButtonInner,
  MobileBagCount,
  MobileHeaderDropdown,
  MobileHeaderNavigation,
  MobileIconButton,
  MobileLogo,
  MobileLogoLink,
  MobileMenuSublevel,
  MobileMenuSublevelInner,
  MobileNavigation,
  MobilePrimaryNavigation,
  MobileSearchContainer,
  MobileSecondaryNavigation,
  NavigationButton,
  NavigationLink,
  Sublevel,
  SublevelClose,
  SublevelInner,
} from '@components/header/header.styles';
import { HOME_ROUTE, LANDMARK_ROLES, ROUTE } from '@utils/constants';
import { HEADER_TOP, SUBLEVEL } from '@components/header/header.constants';
import { SearchInput } from '@components/searchInput';
import { renderWhenTrue, renderWhenTrueOtherwise } from '@utils/rendering';
import { useGetShoppingCart } from '@hooks/useShoppingCart';
import { useCustomer } from '@hooks/useCustomer';
import { useShippingCountry } from '@hooks/useShipping';
import { preventTabIndex } from '@utils/helpers';
import { headerMessages } from '@components/header/header.messages';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { usePreviewLogin } from '@hooks/useCmsUserAuth';
import { useArtistDropdown, useDropdown, useGroupings, useObjectstDropdown } from '@hooks/useHeader/useHeader';
import { LinkSetType } from '@definitions/common.types';
import { FeatureNavbar } from '@definitions/articles.types';
import { COLORS } from '@theme/colors';

import IconBag from '../../../assets/images/icon-bag.svg';
import IconSearch from '../../../assets/images/icon-search.svg';
import IconClose from '../../../assets/images/icon-x.svg';
import IconMenu from '../../../assets/images/icon-menu.svg';
import { totalQuantity } from '../header.utils';

export interface MobileHeaderProps {
  headerTop: HEADER_TOP;
  closeAllSublevels: () => void;
  toggleOpenSublevel: (sublevel: SUBLEVEL) => void;
  renderAccountLinkMobile: (isAuthed: boolean) => React.ReactNode;
  setHeaderMobileMenuOpen: (isHeaderMobileMenuOpen: boolean) => void;
  setLoading: (loading: boolean) => void;
  sublevelOpen: boolean;
  mobileMenuOpen: boolean;
  openHeaderSublevel: (data: { sublevel: SUBLEVEL }) => void;
  handleSearchCancel: () => void;
  searchPlaceholder: string;
  renderSublevel: ({ sublevel, isMobile }: { sublevel: SUBLEVEL | null; isMobile: boolean }) => React.ReactNode;
  closeHeaderSublevel: () => void;
  sublevel: SUBLEVEL | null;
  featureNavbar?: FeatureNavbar;
  pathname?: string;
}

export const MobileHeader = ({
  headerTop,
  closeAllSublevels,
  toggleOpenSublevel,
  renderAccountLinkMobile,
  setHeaderMobileMenuOpen,
  sublevelOpen,
  mobileMenuOpen,
  openHeaderSublevel,
  searchPlaceholder,
  renderSublevel,
  closeHeaderSublevel,
  sublevel,
  setLoading,
  handleSearchCancel,
  featureNavbar,
  pathname,
}: MobileHeaderProps) => {
  const isMenuOpen = mobileMenuOpen || sublevelOpen;
  const { lineItemsLength, lineItems } = useGetShoppingCart();
  const { dropdown, fetchDropdown } = useDropdown();
  const { artistDropdown, fetchArtistDropdown } = useArtistDropdown();
  const { objectsDropdown, fetchObjectsDropdown } = useObjectstDropdown();

  const { groupings, fetchGroupings } = useGroupings();
  const { isMobile } = useBreakpoint();
  const { isAuthed: isPreview } = usePreviewLogin();
  const intl = useIntl();
  const router = useRouter();
  const isPDP = !!router.query?.slug;
  const { isAuthed } = useCustomer();
  const { shippingCountryCode } = useShippingCountry();
  const isSearch = propEq('route', ROUTE.SEARCH, router);

  useAsync(async () => {
    await fetchDropdown();
    await fetchGroupings();
    await fetchArtistDropdown();
    await fetchObjectsDropdown();
  }, [isPreview]);

  const viewAllObject = {
    link: {
      type: 'internal',
      slug: 'artists/',
      displayText: 'View All Artists',
      urlPath: '',
    },
  };

  const firstTwelveArtist = artistDropdown?.slice(0, 12) ?? [];
  const updatedObjectsDropdown = objectsDropdown?.slice(0, 12) ?? [];

  let dropdownArtists = null;
  if (artistDropdown && artistDropdown.length > 0) {
    dropdownArtists = [...firstTwelveArtist, viewAllObject];
  }

  const renderMobileBagCount = renderWhenTrue(always(<MobileBagCount>{totalQuantity(lineItems)}</MobileBagCount>));
  const renderMobileMenuButton = renderWhenTrueOtherwise(
    always(
      <MobileIconButton
        onClick={() => setHeaderMobileMenuOpen(true)}
        tabIndex={preventTabIndex(isSearch)}
        aria-label={intl.formatMessage(headerMessages.menuLabel)}
      >
        <IconMenu />
      </MobileIconButton>
    ),
    always(
      <MobileIconButton
        onClick={closeAllSublevels}
        tabIndex={preventTabIndex(isSearch)}
        aria-label={intl.formatMessage(headerMessages.closeAllSubLevelsLabel)}
      >
        <IconClose />
      </MobileIconButton>
    )
  );

  const renderSearchInput = renderWhenTrue(
    always(
      <SearchInput
        type="text"
        placeholder={searchPlaceholder}
        isSearch={isSearch}
        setLoading={setLoading}
        tabIndex={preventTabIndex(!isSearch)}
      />
    )
  );

  const renderHeaderDropdown = renderWhenTrue(
    always(
      <Item>
        <MobileHeaderDropdown>
          {dropdown?.map(({ link: { displayText, slug, type, urlPath } }, index) => {
            return (
              <Item key={index}>
                <a href={type === LinkSetType.INTERNAL ? `/${slug}` : urlPath}>
                  <NavigationLink
                    style={{ paddingLeft: 50 }}
                    onClick={closeAllSublevels}
                    tabIndex={preventTabIndex(isSearch)}
                  >
                    <FormattedMessage id={`dropdown-header-${slug}`} defaultMessage={displayText} />
                  </NavigationLink>
                </a>
              </Item>
            );
          })}
        </MobileHeaderDropdown>
      </Item>
    )
  );

  const renderArtistDropdown = renderWhenTrue(
    always(
      <Item>
        <MobileHeaderDropdown>
          {dropdownArtists?.map(({ link: { displayText, slug, type, urlPath } }, index) => {
            return displayText === 'View All Artists' ? (
              <NextLink href={`${ROUTE.ARTISTS}`} as={`${ROUTE.ARTISTS}`} passHref>
                <NavigationLink
                  style={{ paddingLeft: 50 }}
                  isActive={router.pathname?.includes(ROUTE.ARTISTS)}
                  onClick={closeAllSublevels}
                  tabIndex={preventTabIndex(isSearch)}
                  pathname={pathname}
                  openSublevel={sublevelOpen}
                >
                  View All Artists
                </NavigationLink>
              </NextLink>
            ) : (
              <Item key={index}>
                <NextLink href={type === LinkSetType.INTERNAL ? `/${slug}` : urlPath} passHref>
                  <NavigationLink
                    style={{ paddingLeft: 50 }}
                    onClick={closeAllSublevels}
                    tabIndex={preventTabIndex(isSearch)}
                  >
                    <FormattedMessage id={`dropdown-header-${slug}`} defaultMessage={displayText} />
                  </NavigationLink>
                </NextLink>
              </Item>
            );
          })}
        </MobileHeaderDropdown>
      </Item>
    )
  );

  const renderObjectsDropdown = renderWhenTrue(
    always(
      <Item>
        <MobileHeaderDropdown>
          {updatedObjectsDropdown?.map(({ link: { displayText, slug, type, urlPath } }, index) => {
            return (
              <Item key={index}>
                <a href={type === LinkSetType.INTERNAL ? `/${slug}` : urlPath}>
                  <NavigationLink
                    style={{ paddingLeft: 50 }}
                    onClick={closeAllSublevels}
                    tabIndex={preventTabIndex(isSearch)}
                  >
                    <FormattedMessage id={`objectsDropdown-header-${slug}`} defaultMessage={displayText} />
                  </NavigationLink>
                </a>
              </Item>
            );
          })}
        </MobileHeaderDropdown>
      </Item>
    )
  );

  const renderNavigationLink = () => (
    <NavigationLink onClick={closeAllSublevels} tabIndex={preventTabIndex(isSearch)}>
      <FormattedMessage {...headerMessages.artworks} />
    </NavigationLink>
  );

  const renderArtworksLink = renderWhenTrueOtherwise(
    () =>
      groupings?.map(({ slug }, index) => {
        return (
          index === 0 && (
            <Item>
              <NextLink href={ROUTE.GROUPINGS_ARTWORKS} passHref>
                {renderNavigationLink()}
              </NextLink>
            </Item>
          )
        );
      }),
    () => (
      <Item>
        <NextLink href={ROUTE.GROUPINGS_ARTWORKS} passHref>
          {renderNavigationLink()}
        </NextLink>
      </Item>
    )
  );

  return (
    <MobileHeaderNavigation role={LANDMARK_ROLES.NAVIGATION} tabIndex={-1}>
      <MobileNavigation
        data-testid="mobile-navigation-test-id"
        headerTop={headerTop}
        pathname={pathname}
        openSublevel={isMenuOpen}
        navbarColor={featureNavbar?.navbarColor}
        navbarTextColor={featureNavbar?.navbarTextColor}
        isPDP={isPDP}
      >
        <MobileLogo data-testid="mobile-logo-test-id">
          <NextLink href={HOME_ROUTE} passHref>
            <MobileLogoLink onClick={closeAllSublevels}>
              <FormattedMessage {...headerMessages.platform} />
            </MobileLogoLink>
          </NextLink>
        </MobileLogo>
        <MenuItem pathname={pathname} openSublevel={isMenuOpen}>
          <NextLink href={ROUTE.SEARCH} passHref>
            <NavigationLink
              onClick={closeAllSublevels}
              tabIndex={preventTabIndex(isSearch)}
              aria-label={intl.formatMessage(headerMessages.search)}
              pathname={pathname}
            >
              <IconSearch />
            </NavigationLink>
          </NextLink>
        </MenuItem>
        <MenuItem
          onClick={() => toggleOpenSublevel(SUBLEVEL.BAG)}
          tabIndex={preventTabIndex(isSearch)}
          pathname={pathname}
          openSublevel={isMenuOpen}
        >
          <MobileBagButtonInner aria-label={intl.formatMessage(headerMessages.bag)}>
            <IconBag />
            {renderMobileBagCount(!!lineItemsLength)}
          </MobileBagButtonInner>
        </MenuItem>
        <MenuItem pathname={pathname} openSublevel={isMenuOpen}>
          {renderMobileMenuButton(!sublevelOpen && !mobileMenuOpen)}
        </MenuItem>
        <MobileSearchContainer
          initial={{ y: isSearch ? '100%' : 0, opacity: isSearch ? 1 : 0 }}
          animate={{ y: isSearch ? '100%' : 0, opacity: isSearch ? 1 : 0 }}
          transition={{ ease: 'linear', duration: 0.2, delay: 0.4 }}
        >
          {renderSearchInput(isMobile)}
          <CancelLink onClick={handleSearchCancel} tabIndex={preventTabIndex(!isSearch)}>
            <FormattedMessage {...headerMessages.cancel} />
          </CancelLink>
        </MobileSearchContainer>
      </MobileNavigation>

      <MobileMenuSublevel style={{ marginTop: 0.5 }} openMobileMenu={mobileMenuOpen}>
        <MobileMenuSublevelInner openMobileMenu={mobileMenuOpen} openSublevel={sublevelOpen}>
          <MobilePrimaryNavigation>
            {renderArtworksLink(!!groupings && groupings.length > 0)}
            {renderHeaderDropdown(!!dropdown)}

            <div style={{ height: 1, backgroundColor: COLORS.BLACK40, margin: 15 }} />

            <Item>
              <NextLink href={`${ROUTE.OBJECTS}`} as={`${ROUTE.OBJECTS}`} passHref>
                <NavigationLink
                  isActive={router.pathname?.includes(ROUTE.OBJECTS)}
                  onClick={closeAllSublevels}
                  tabIndex={preventTabIndex(isSearch)}
                  pathname={pathname}
                  openSublevel={sublevelOpen}
                >
                  <FormattedMessage {...headerMessages.objects} />
                </NavigationLink>
              </NextLink>
            </Item>
            {renderObjectsDropdown(!!objectsDropdown)}

            <div style={{ height: 1, backgroundColor: COLORS.BLACK40, margin: 15 }} />
            <Item>
              <NextLink href={`${ROUTE.ARTISTS}`} as={`${ROUTE.ARTISTS}`} passHref>
                <NavigationLink
                  isActive={router.pathname?.includes(ROUTE.ARTISTS)}
                  onClick={closeAllSublevels}
                  tabIndex={preventTabIndex(isSearch)}
                  pathname={pathname}
                  openSublevel={sublevelOpen}
                >
                  <FormattedMessage {...headerMessages.artists} />
                </NavigationLink>
              </NextLink>
            </Item>
            {renderArtistDropdown(!!artistDropdown)}
            <div style={{ height: 1, backgroundColor: COLORS.BLACK40, margin: 15 }} />
          </MobilePrimaryNavigation>
          <MobileSecondaryNavigation>
            <Item>
              <NavigationButton
                onClick={() => openHeaderSublevel({ sublevel: SUBLEVEL.COUNTRY })}
                tabIndex={preventTabIndex(isSearch)}
              >
                <FormattedMessage {...headerMessages.shipping} values={{ country: shippingCountryCode }} />
              </NavigationButton>
            </Item>
            <Item>{renderAccountLinkMobile(isAuthed)}</Item>
          </MobileSecondaryNavigation>
        </MobileMenuSublevelInner>
      </MobileMenuSublevel>

      <Sublevel openSublevel={sublevelOpen}>
        <SublevelInner openSublevel={sublevelOpen}>
          {renderSublevel({ sublevel, isMobile: true })}
          {mobileMenuOpen && <SublevelClose onClick={closeHeaderSublevel} />}
        </SublevelInner>
      </Sublevel>
    </MobileHeaderNavigation>
  );
};
