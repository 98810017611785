import { useDispatch, useSelector } from 'react-redux';
import { AnyAction, ThunkDispatch, unwrapResult } from '@reduxjs/toolkit';
import { useRouter } from 'next/router';

import { plpActions, plpSelectors, PLP_DOMAINS, PLP_PREFIXES } from '@modules/plp';
import { ROUTE } from '@utils/constants';
import {
  Artwork,
  ArtworksState,
  ChangeGridSizePayload,
  FetchArtworksState,
  FetchGroupingArtworksState,
  SetInitialArtworkPayload,
} from '@definitions/artworks.types';
import { GlobalState } from '@modules/createStore';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { CurrentPlp, determinePlpContext } from '@modules/plp/plp.helpers';

type AppDispatch = ThunkDispatch<GlobalState, any, AnyAction>;

export const useChangeGridSize = (currentPlp: CurrentPlp) => {
  const dispatch = useDispatch();
  const prefix: string = determinePlpContext(PLP_PREFIXES, currentPlp);
  const domain: (state: GlobalState) => ArtworksState = determinePlpContext(PLP_DOMAINS, currentPlp);

  const gridSize = useSelector(plpSelectors.selectGridSize(domain));
  const changeGridSize = (data: ChangeGridSizePayload) => dispatch(plpActions.changeGridSize(prefix)(data));

  return { gridSize, changeGridSize };
};

export const useFetchArtworks = (currentPlp: CurrentPlp) => {
  const dispatch: AppDispatch = useDispatch();
  const prefix: string = determinePlpContext(PLP_PREFIXES, currentPlp);
  const domain: (state: GlobalState) => ArtworksState = determinePlpContext(PLP_DOMAINS, currentPlp);

  const artworks = useSelector(plpSelectors.selectArtworks(domain));
  const meta = useSelector(plpSelectors.selectMeta(domain));
  const loading = useSelector(plpSelectors.selectLoading(domain));
  const fetchArtworks = (data: FetchArtworksState) =>
    dispatch(plpActions.fetchArtworks(prefix)(data)).then(unwrapResult);

  return { artworks, meta, loading, fetchArtworks };
};

export const useFetchFavoriteArtworks = (currentPlp: CurrentPlp) => {
  const dispatch: AppDispatch = useDispatch();
  const prefix: string = determinePlpContext(PLP_PREFIXES, currentPlp);
  const domain: (state: GlobalState) => ArtworksState = determinePlpContext(PLP_DOMAINS, currentPlp);

  const favoriteArtworks = useSelector(plpSelectors.selectFavoriteArtworks(domain));
  const fetchFavoriteArtworks = (data: FetchGroupingArtworksState) =>
    dispatch(plpActions.fetchFavoriteArtworks(prefix)(data)).then(unwrapResult);

  return { favoriteArtworks, fetchFavoriteArtworks };
};

export const useSetInitialArtworks = (currentPlp: CurrentPlp) => {
  const dispatch = useDispatch();
  const prefix: string = determinePlpContext(PLP_PREFIXES, currentPlp);
  const domain: (state: GlobalState) => ArtworksState = determinePlpContext(PLP_DOMAINS, currentPlp);

  const artworks = useSelector(plpSelectors.selectArtworks(domain));
  const setInitialArtworks = (data: SetInitialArtworkPayload) => dispatch(plpActions.setInitialArtworks(prefix)(data));

  return { artworks, setInitialArtworks };
};

export const useResetArtworks = (currentPlp: CurrentPlp) => {
  const dispatch = useDispatch();
  const prefix: string = determinePlpContext(PLP_PREFIXES, currentPlp);

  const resetArtworks = () => dispatch(plpActions.resetArtworks(prefix)());

  return { resetArtworks };
};

export const useSetCurrentPage = (currentPlp: CurrentPlp) => {
  const dispatch = useDispatch();
  const prefix: string = determinePlpContext(PLP_PREFIXES, currentPlp);
  const domain: (state: GlobalState) => ArtworksState = determinePlpContext(PLP_DOMAINS, currentPlp);

  const currentPage = useSelector(plpSelectors.selectCurrentPage(domain));
  const setCurrentPage = (page: number) => dispatch(plpActions.setCurrentPage(prefix)(page));

  return { setCurrentPage, currentPage };
};

export const useSellingState = (
  artwork: Artwork
): {
  isBeforeSellingDate: boolean;
  isWithinSellingDates: boolean;
  isPrelaunchable: boolean;
  isEnding: boolean;
  renderExpiryDate: () => string;
  isPostSellingDate: boolean;
} => {
  const { liveStartAt, liveEndAt, inventoryLevel } = artwork;
  const now: number = new Date().getTime();
  const oneMonth: number = 60 * 60 * 1000 * 24 * 30;
  const startSellingDate: number = new Date(liveStartAt).getTime();
  const endSellingDate: number = new Date(liveEndAt).getTime();
  const isBeforeSellingDate: boolean = now < startSellingDate;
  const isWithinSellingDates: boolean = now > startSellingDate && now < endSellingDate;
  const isPrelaunchable: boolean = inventoryLevel <= 0 && isBeforeSellingDate;
  const isEnding: boolean = endSellingDate - now < oneMonth;
  const isPostSellingDate = now > endSellingDate;

  function renderExpiryDate(): string {
    const date = new Date(endSellingDate);
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  }

  return { isBeforeSellingDate, isWithinSellingDates, isPrelaunchable, isEnding, renderExpiryDate, isPostSellingDate };
};

export const useWaitlistButtonLabel = ({
  isShoppable,
  isExclusive = false,
  isPdp = false,
  isPostSellingDate = false,
  isWithinSellingDates = false,
  isBeforeSellingDate = false
}: {
  isShoppable: boolean;
  isExclusive?: boolean;
  isPdp?: boolean;
  isPostSellingDate?: boolean;
  isWithinSellingDates? : boolean;
  isBeforeSellingDate? : boolean
}): string => {
  const { isMobile } = useBreakpoint();
  const waitlistLabel = isMobile || isPdp ? 'WAITLIST' : 'ADD TO WAITLIST';
  const notifyLabel = isMobile && isPdp ? 'NOTIFY' : 'NOTIFY ME';

  if(isWithinSellingDates && !isPdp){
    return waitlistLabel;
  }

  if(isBeforeSellingDate && !isPdp) {
    return notifyLabel;
  }

  if((isPostSellingDate && !isPdp) ){
    return 'GET IN TOUCH';
  }

  if (isPostSellingDate) {
    return 'GET IN TOUCH';
  }

  if (isExclusive) {
    if (isShoppable) {
      return waitlistLabel;
    }

    return notifyLabel;
  }

  return waitlistLabel;
};

export const useArtworksRoute = () => {
  const router = useRouter();
  const isGroupingRoute = router.pathname?.includes(ROUTE.GROUPINGS);
  const isTradeMemberRoute = router.pathname === ROUTE.TRADE;
  const isArtworksRoute = router.pathname === ROUTE.ARTWORKS;

  return { isGroupingRoute, isArtworksRoute, isTradeMemberRoute };
};
