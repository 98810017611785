import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AnyAction, ThunkDispatch, unwrapResult } from '@reduxjs/toolkit';

import { GlobalState } from '@modules/createStore';
import { headerActions, headerSelectors } from '@modules/header';

export interface UseGrouping {
  slug: string;
}

type AppDispatch = ThunkDispatch<GlobalState, any, AnyAction>;

export const useGroupings = () => {
  const dispatch: AppDispatch = useDispatch();
  const groupings = useSelector(headerSelectors.selectHeaderGroupings);
  const fetchGroupings = () => dispatch(headerActions.fetchHeaderGroupings());

  return { groupings, fetchGroupings };
};

export const useDropdown = () => {
  const dispatch: AppDispatch = useDispatch();
  const dropdown = useSelector(headerSelectors.selectHeaderDropdown);
  const fetchDropdown = () => dispatch(headerActions.fetchHeaderDropdown());

  return { dropdown, fetchDropdown };
};

export const useArtistDropdown = () => {
  const dispatch: AppDispatch = useDispatch();
  const artistDropdown = useSelector(headerSelectors.selectArtistHeaderDropdown);
  const fetchArtistDropdown = () => dispatch(headerActions.fetchArtistHeaderDropdown());

  return { artistDropdown, fetchArtistDropdown };
};

export const useObjectstDropdown = () => {
  const dispatch: AppDispatch = useDispatch();
  const objectsDropdown = useSelector(headerSelectors.selectObjectsHeaderDropdown);
  const fetchObjectsDropdown = () => dispatch(headerActions.fetchObjetsHeaderDropdown());

  return { objectsDropdown, fetchObjectsDropdown };
};

export const useGrouping = ({ slug }: UseGrouping) => {
  const dispatch: AppDispatch = useDispatch();
  const grouping = useSelector(headerSelectors.selectHeaderGrouping);
  const fetchGrouping = () => dispatch(headerActions.fetchHeaderGrouping({ slug }));

  return { grouping, fetchGrouping };
};
