import React, { useState } from 'react';
import NextLink from 'next/link';
import { FormattedMessage, useIntl } from 'react-intl';
import { always, propEq } from 'ramda';
import { useRouter } from 'next/router';
import { useAsync } from 'react-use';

import {
  CancelLink,
  DesktopHeaderNavigation,
  Item,
  DesktopMenuItem,
  MainLogo,
  Navigation,
  NavigationButton,
  NavigationLink,
  PrimaryNavigation,
  SearchItem,
  SecondaryNavigation,
  Sublevel,
  SublevelClose,
  SublevelInner,
  SublevelLine,
  DesktopBagCount,
  DesktopBagButtonInner,
  IconFavorite,
} from '@components/header/header.styles';
import { HOME_ROUTE, ROUTE, LANDMARK_ROLES } from '@utils/constants';
import { BAG_TEST_ID, SEARCH_TEST_ID, SUBLEVEL } from '@components/header/header.constants';
import { SearchInput } from '@components/searchInput';
import { getHeaderAnimationProps, preventTabIndex } from '@utils/helpers';
import { renderWhenTrue } from '@utils/rendering';
import { useGetShoppingCart } from '@hooks/useShoppingCart';
import { useShippingCountry } from '@hooks/useShipping';
import { useCustomer } from '@hooks/useCustomer';
import { headerMessages } from '@components/header/header.messages';
import { useBreakpoint } from '@hooks/useBreakpoint';
import { useDropdown, useGroupings, useArtistDropdown, useObjectstDropdown } from '@hooks/useHeader/useHeader';
import { FeatureNavbar } from '@definitions/articles.types';
import { DROPDOWN_TYPES } from '@components/PLP/artworksView/artworksView.component';
import { usePreviewLogin } from '@hooks/useCmsUserAuth';
import { COLORS } from '@theme/colors';

import IconSearch from '../../../assets/images/icon-search.svg';
import IconBag from '../../../assets/images/icon-bag.svg';
import { totalQuantity } from '../header.utils';

import { HeaderDropdown } from './headerDropdown';
import { ArtistHeaderDropdown } from './artistHeaderDropdown';
import { ObjectHeaderDropdown } from './objectHeaderDropdown';

export interface DesktopHeaderProps {
  sublevelOpen: boolean;
  closeAllSublevels: () => void;
  handleSearchCancel: () => void;
  renderSublevel: ({ sublevel, isMobile }: { sublevel: SUBLEVEL | null; isMobile?: boolean }) => React.ReactNode;
  sublevel: SUBLEVEL | null;
  toggleOpenSublevel: (sublevel: SUBLEVEL) => void;
  toggleDropdown: (type: DROPDOWN_TYPES) => void;
  searchPlaceholder: string;
  setLoading: (loading: boolean) => void;
  renderAccountLink: (isAuthed: boolean) => React.ReactNode;
  featureNavbar?: FeatureNavbar;
  pathname?: string;
}

export const DesktopHeader = ({
  sublevelOpen,
  closeAllSublevels,
  renderSublevel,
  sublevel,
  toggleOpenSublevel,
  toggleDropdown,
  searchPlaceholder,
  setLoading,
  renderAccountLink,
  handleSearchCancel,
  featureNavbar,
  pathname,
}: DesktopHeaderProps) => {
  const [isTranspatentBackground, setIsTransparentBackground] = useState(false);
  const router = useRouter();
  const intl = useIntl();
  const { dropdown, fetchDropdown } = useDropdown();
  const { artistDropdown, fetchArtistDropdown } = useArtistDropdown();
  const { objectsDropdown, fetchObjectsDropdown } = useObjectstDropdown();
  const { groupings, fetchGroupings } = useGroupings();
  const { isAuthed } = useCustomer();
  const { isAuthed: isPreview } = usePreviewLogin();
  const { isMobile } = useBreakpoint();
  const isSearch = propEq('route', ROUTE.SEARCH, router);
  const headerAnimationProps = getHeaderAnimationProps(isSearch);
  const { lineItemsLength, lineItems } = useGetShoppingCart();
  const { shippingCountryCode } = useShippingCountry();

  useAsync(async () => {
    await fetchDropdown();
    await fetchArtistDropdown();
    await fetchObjectsDropdown();
    await fetchGroupings();
  }, [isPreview]);

  const handleFavoritesClick = () => {
    if (isAuthed) {
      toggleDropdown(DROPDOWN_TYPES.FAVOURITE);
    } else {
      toggleOpenSublevel(SUBLEVEL.ACCOUNT);
    }
  };

  const renderSearchText = renderWhenTrue(
    always(
      <Item>
        <FormattedMessage {...headerMessages.search} />
      </Item>
    )
  );
  const renderSearchColon = renderWhenTrue(always(':'));

  const renderDesktopBagCount = renderWhenTrue(always(<DesktopBagCount>{totalQuantity(lineItems)}</DesktopBagCount>));

  const renderSearchInput = renderWhenTrue(
    always(
      <SearchInput
        type="text"
        placeholder={searchPlaceholder}
        isSearch={isSearch}
        setLoading={setLoading}
        tabIndex={preventTabIndex(!isSearch)}
      />
    )
  );

  const renderArtworksDropdown = renderWhenTrue(() => (
    <Item>
      <HeaderDropdown
        onMouseOver={() => setIsTransparentBackground(true)}
        onMouseOut={() => setIsTransparentBackground(false)}
        items={dropdown ?? []}
        groupings={groupings ?? []}
      />
    </Item>
  ));

  const renderArtistDropdown = renderWhenTrue(() => (
    <Item>
      <ArtistHeaderDropdown
        onMouseOver={() => setIsTransparentBackground(true)}
        onMouseOut={() => setIsTransparentBackground(false)}
        items={artistDropdown ?? []}
      />
    </Item>
  ));

  const renderObjectDropdown = renderWhenTrue(() => (
    <Item>
      <ObjectHeaderDropdown
        onMouseOver={() => setIsTransparentBackground(true)}
        onMouseOut={() => setIsTransparentBackground(false)}
        items={objectsDropdown ?? []}
      />
    </Item>
  ));

  const renderArtworksLink = renderWhenTrue(() => (
    <Item>
      <a
        onClick={() => {
          window.history.pushState({}, '', ROUTE.ARTWORKS);
        }}
        href={`${ROUTE.ARTWORKS}`}
      >
        <NavigationLink
          isActive={router.pathname?.includes(ROUTE.ARTWORKS)}
          onClick={closeAllSublevels}
          tabIndex={preventTabIndex(isSearch)}
          pathname={pathname}
          openSublevel={sublevelOpen}
        >
          <FormattedMessage {...headerMessages.artworks} />
        </NavigationLink>
      </a>
    </Item>
  ));

  const renderArtistLink = renderWhenTrue(() => (
    <Item>
      <a
        onClick={() => {
          window.history.pushState({}, '', ROUTE.ARTISTS);
        }}
        href={`${ROUTE.ARTISTS}`}
      >
        <NavigationLink
          isActive={router.pathname?.includes(ROUTE.ARTISTS)}
          onClick={closeAllSublevels}
          tabIndex={preventTabIndex(isSearch)}
          pathname={pathname}
          openSublevel={sublevelOpen}
        >
          <FormattedMessage {...headerMessages.artists} />
        </NavigationLink>
      </a>
    </Item>
  ));

  return (
    <DesktopHeaderNavigation role={LANDMARK_ROLES.NAVIGATION}>
      <Sublevel openSublevel={sublevelOpen}>
        <SublevelInner openSublevel={sublevelOpen}>
          <SublevelLine />
          {renderSublevel({ sublevel })}
          <SublevelClose
            onClick={closeAllSublevels}
            tabIndex={preventTabIndex(!sublevel)}
            aria-label={intl.formatMessage(headerMessages.closeSublevelLabel, { sublevel })}
          />
        </SublevelInner>
      </Sublevel>

      <Navigation
        isTransparentBackground={isTranspatentBackground}
        openSublevel={sublevelOpen}
        layoutId="navigation"
        pathname={pathname}
        data-testid="desktop-navigation-test-id"
        navbarColor={featureNavbar?.navbarColor}
        navbarTextColor={isTranspatentBackground ? COLORS.BLACK50 : COLORS.BLACK}
        isSearch={isSearch}
      >
        <MainLogo isSearch={isSearch} openSublevel={sublevelOpen} data-testid="main-logo-test-id">
          <a
            href={HOME_ROUTE}
            onClick={() => {
              window.history.pushState({}, '', HOME_ROUTE);
              window.location.href = HOME_ROUTE;
            }}
          >
            <NavigationLink
              onClick={closeAllSublevels}
              onFocus={closeAllSublevels}
              pathname={pathname}
              openSublevel={sublevelOpen}
              noHover
            >
              <FormattedMessage {...headerMessages.platform} />
            </NavigationLink>
          </a>
        </MainLogo>
        <PrimaryNavigation {...headerAnimationProps.primary}>
          {renderArtworksDropdown(dropdown && dropdown.length > 0)}
          {renderArtworksLink(!dropdown || dropdown?.length === 0)}

          {renderObjectDropdown(objectsDropdown && objectsDropdown.length > 0)}
          {renderObjectDropdown(!objectsDropdown || objectsDropdown?.length === 0)}

          {renderArtistDropdown(artistDropdown && artistDropdown.length > 0)}
          {renderArtistLink(!artistDropdown || artistDropdown?.length === 0)}

          {renderSearchText(isSearch)}
          {renderSearchColon(isSearch)}

          <SearchItem isSearch={isSearch} data-testid={SEARCH_TEST_ID}>
            {renderSearchInput(!isMobile)}
            <CancelLink onClick={handleSearchCancel} tabIndex={preventTabIndex(!isSearch)}>
              <FormattedMessage {...headerMessages.cancel} />
            </CancelLink>
          </SearchItem>
        </PrimaryNavigation>
        <SecondaryNavigation {...headerAnimationProps.secondary}>
          <Item>
            <NavigationButton
              onClick={() => toggleOpenSublevel(SUBLEVEL.COUNTRY)}
              tabIndex={preventTabIndex(isSearch)}
              aria-label={intl.formatMessage(headerMessages.shippingLabel, { country: shippingCountryCode })}
              pathname={pathname}
              openSublevel={sublevelOpen}
            >
              {shippingCountryCode}
            </NavigationButton>
          </Item>
          <Item>{renderAccountLink(isAuthed)}</Item>
          <Item>
            <NavigationButton
              onClick={() => handleFavoritesClick()}
              tabIndex={preventTabIndex(isSearch)}
              aria-label={intl.formatMessage(headerMessages.favorites)}
              pathname={pathname}
              openSublevel={sublevelOpen}
            >
              <IconFavorite />
            </NavigationButton>
          </Item>

          <DesktopMenuItem
            onClick={() => toggleOpenSublevel(SUBLEVEL.BAG)}
            tabIndex={preventTabIndex(isSearch)}
            data-testid={BAG_TEST_ID}
            pathname={pathname}
            openSublevel={sublevelOpen}
          >
            <DesktopBagButtonInner aria-label={intl.formatMessage(headerMessages.bag)}>
              <IconBag />
              {renderDesktopBagCount(!!lineItemsLength)}
            </DesktopBagButtonInner>
          </DesktopMenuItem>
          <DesktopMenuItem pathname={pathname} openSublevel={sublevelOpen}>
            <NextLink href={ROUTE.SEARCH} passHref>
              <NavigationLink
                onClick={closeAllSublevels}
                tabIndex={preventTabIndex(isSearch)}
                aria-label={intl.formatMessage(headerMessages.search)}
              >
                <IconSearch />
              </NavigationLink>
            </NextLink>
          </DesktopMenuItem>
        </SecondaryNavigation>
      </Navigation>
    </DesktopHeaderNavigation>
  );
};
