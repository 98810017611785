import React, { useState } from 'react';
import { Variants } from 'framer-motion';
import NextLink from 'next/link';
import { useRouter } from 'next/router';

import { Dropdown, Grouping } from '@definitions/header.types';
import { LinkSetType } from '@definitions/common.types';
import { ROUTE } from '@utils/constants';
import { renderWhenTrue, renderWhenTrueOtherwise } from '@utils/rendering';

import { Container, Trigger, List, Item, Link, CustomDiv } from './objectHeaderDropdown.styles';

interface iHeaderDropdown {
  items: Dropdown[];
  onMouseOver: () => void;
  onMouseOut: () => void;
}

const itemVariants: Variants = {
  open: {
    opacity: 1,
    y: 0,
    transition: { type: 'spring', stiffness: 250, damping: 25 },
  },
  closed: { opacity: 0, y: -20, transition: { duration: 0.3 } },
};

export const ObjectHeaderDropdown = ({ items, onMouseOver, onMouseOut }: iHeaderDropdown) => {
  const viewAllObject = {
    link: {
      type: 'internal',
      slug: 'artists/',
      displayText: 'View All Artists',
      urlPath: '',
    },
  };
  const [isOpen, setIsOpen] = useState(false);
  const router = useRouter();
  const updatedObjectsDropdown = items.slice(0, 12);

  const renderTrigger = () => (
    <Trigger
      isActive={true}
      isOpen={isOpen}
      onMouseOver={() => {
        setIsOpen(true);
        onMouseOver();
      }}
    >
      Objects
    </Trigger>
  );

  const renderObjectsLink = renderWhenTrueOtherwise(
    () =>
      items.map(({ link: { slug } }, index) => {
        return (
          index === 0 && (
            <a key={index} href={ROUTE.OBJECTS} >
              {renderTrigger()}
            </a>
          )
        );
      }),
    () => <a href={`${ROUTE.OBJECTS}`}>{renderTrigger()}</a>
  );

  const renderList = renderWhenTrue(() => (
    <List
      initial={false}
      style={{ pointerEvents: isOpen ? 'auto' : 'none' }}
      animate={isOpen ? 'open' : 'closed'}
      variants={{
        open: {
          transition: {
            type: 'spring',
            bounce: 0,
            duration: 0,
            delayChildren: 0,
            staggerChildren: 0,
          },
        },
        closed: {
          transition: {
            type: 'spring',
            bounce: 0,
            duration: 0.3,
          },
        },
      }}
    >
      <CustomDiv>
        {updatedObjectsDropdown.map(({ link: { slug, displayText, type, urlPath } }, index) => (
          <Item variants={itemVariants} key={index} displayText={displayText}>
            <a
              onClick={(e) => {
                e.preventDefault();
                const targetUrl = type === LinkSetType.INTERNAL ? `/${slug}` : urlPath;
                if(slug){window.history.pushState({}, '', targetUrl);}
                window.location.href = targetUrl;
              }}
              href={type === LinkSetType.INTERNAL ? `/${slug}` : urlPath}
            >
              <Link style={{ paddingLeft: 198 }}>{displayText} </Link>
            </a>
          </Item>
        ))}
      </CustomDiv>
    </List>
  ));

  return (
    <Container
      onMouseLeave={() => {
        setIsOpen(false);
        onMouseOut();
      }}
    >
      {renderObjectsLink(items.length > 0)}
      {renderList(router.pathname !== ROUTE.TRADE)}
    </Container>
  );
};
